import { translate } from '@/domains/core/localization/translate';
export const {
  title,
  subTitle,
  text,
  button
} = translate({
  "title": "Errore 404",
  "subTitle": "Ops, c'\xE8 stato un imprevisto...",
  "text": "Niente paura, toma a visitare il nostro sito!",
  "button": "Torna alla homepage"
});